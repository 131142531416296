<template>
    <div>
        <div >
            <section class="main">
                <div class="logo text-center">
                    <h1> <a href="index.html"> 测康智能科技平台</a></h1>
                </div>
                <div class="content-w3ls text-center" >
                        <img src="../assets/logincss/images/admin.png" alt="" class="img-responsive">
                    
                        <div class="wthree-field">
                            <el-input   v-model="username"   placeholder="用户名"></el-input>
                        </div>
                        <div class="wthree-field">
                            <el-input   v-model="password" type="password"   placeholder="密码" ></el-input>
                        </div>
                        <div class="wthree-field" v-if="isShow?true:false">
                            <el-input   v-model="phone"   placeholder="联系电话"></el-input>
                        </div>
                        <div class="wthree-field" v-if="isShow?true:false">
                            <el-select v-model="streetvalue" filterable placeholder="请选择街道">
                                <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="wthree-field" v-if="isShow?true:false">
                            <el-input   v-model="information"   placeholder="社区"></el-input>
                        </div>
                        
                        <div class="wthree-field">
                            <button  @click="denglu" class="btn" >{{ buttonName }}</button>
                        </div>
                        <div class="login-bottom" >
                            <a @click="openRe" style="color: burlywood;">
                                <span style="color: white;">{{ idSelect }}</span>
                                <span style="color: #4e6ef2;">{{ idSelect1 }}</span>
                            </a>
                        </div>
                    
                </div>
                
                <div class="copyright">
                    <p>闽ICP备2023010324号-1
                        闽公网安备 35020302035800号</p>
                </div>
            </section>
        </div>
        

    </div>
</template>

<script>
export default {
    data(){
        return{
            isShow:false,
            username:"",
            password:"",
            phone:"",
            information:"",
            buttonName:"登录",
            idSelect:"没有账号？",
            idSelect1:"点击注册！",
            options: [{
            value: '湖里街道',
            label: '湖里街道'
            }, {
            value: '殿前街道',
            label: '殿前街道'
            }, {
            value: '禾山街道',
            label: '禾山街道'
            }, {
            value: '江头街道',
            label: '江头街道'
            }, {
            value: '金山街道',
            label: '金山街道'
            }, {
            value: '厦门国家火炬高技术产业开发区',
            label: '厦门国家火炬高技术产业开发区'
            }, {
            value: '象屿保税区',
            label: '象屿保税区'
            }],
            streetvalue: ''
        }
    },
    beforeCreate(){
        if(sessionStorage.getItem("isLoggedIn")=="true"){
            this.$router.push("/wfhome");
        }
        
    },
	methods:{
        openRe(){
            if(this.idSelect === "没有账号？"){
                this.isShow = true;
                this.buttonName = "注册"    
                this.idSelect = "已有账号！"
                this.idSelect1 ="返回登录！"
            }else if(this.idSelect === "已有账号！"){
                this.isShow = false;
                this.buttonName = "登录"    
                this.idSelect = "没有账号？"
                this.idSelect1 ="点击注册！"
            }
            
        },
        denglu(){
            if(this.buttonName ==="登录"){
                console.log(this.username)
                console.log(this.password)
                this.$http.get(`Home/WfUserLogin?username=${this.username}&password=${this.password}`).then(res => {
                    if(res.status === 200) {
                        console.log(res.data.data)
                        if(res.data.data==true){
                            // 将登录状态保存到 sessionStorage
                            sessionStorage.setItem("isLoggedIn", "true");
                            // 保存账号密码到 sessionStorage（不推荐保存密码，这里仅为示例）
                            sessionStorage.setItem("username", this.username);
                            sessionStorage.setItem("password", this.password);
                            sessionStorage.setItem("lv", res.data.level);
                            if(res.data.mapMessageData!=null){
                                res.data.mapMessageData.forEach((item)=>{
                                    let strings = item.residenceposition.split(',')
                                    let double1 = Number(strings[0])
                                    let double2 = Number(strings[1])
                                    item.residenceposition=[double1,double2]
                                })
                            }
                            console.log("logdata",res.data.mapMessageData)
                            this.$store.commit("setmapMessage",res.data.mapMessageData)
                            this.$store.commit('setLoggedIn', true);

                            this.$router.push("/wfhome");
                        }else{
                            
                            alert('登录失败，请重新输入账号密码')
                        }
                    }else{
                        alert('连接错误,请稍后再试')
                    }
                })
            }else if(this.buttonName==="注册"){
                console.log(this.username)
                console.log(this.password)
                console.log(this.phone)
                console.log(this.information)
                if(this.username.length<6){
                    alert("用户名长度小于六位，请重新输入用户名")
                    this.username=""
                    return
                }

                if (this.password.length >= 8)
                {
                    let hasEnglish = new RegExp(/[a-zA-Z]/).test(this.password);
                    let hasNumber = new RegExp(/[0-9]/).test(this.password);
                    if(!hasNumber || !hasEnglish){
                        alert("密码必须包含英文和数字并大于八位11")
                        this.password=""
                        return
                    }
                }else{
                    alert("密码必须包含英文和数字并大于八位")
                    this.password=""
                    return
                }
                this.$http.get(`Home/WfUserRegister?username=${this.username}&password=${this.password}&phone=${this.phone}&information=${this.information}&street=${this.streetvalue}`).then(res => {
                    if(res.status === 200) {
                        if(res.data.data=="注册成功，返回登录"){
                            alert(res.data.data)
                            this.buttonName = "登录"   
                            this.isShow=false
                        }else{
                            alert(res.data.data)
                        }
                    }else{
                        alert('连接错误,请稍后再试')
                    }
                })

            }
            
        }
    }
}
</script>

<style lang="scss" scoped>

/* reset code */
html {
    scroll-behavior: smooth;
}

body,
html {
    margin: 0;
    padding: 0;
    color: #585858;
}

* {
    box-sizing: border-box;
    font-family: 'Noto Sans JP', sans-serif;
}

/*  wrapper */
.wrapper {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .wrapper {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .wrapper {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .wrapper {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .wrapper {
        max-width: 1140px;
    }
}

/*  /wrapper */

.d-grid {
    display: grid;
}

button,
input,
select {
    -webkit-appearance: none;
    outline: none;
}

button,
.btn,
select {
    cursor: pointer;
}

a {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
    margin: 0;
    padding: 0;
}

body {
    background: #f1f1f1;
    margin: 0;
    padding: 0;
}

form,
fieldset {
    border: 0;
    padding: 0;
    margin: 0;
}

body a:hover {
    text-decoration: none;
}

.clearfix {
    clear: both;
}

/* content */

/*
  Responsive form elements
  Flexbox layout
*/

/*/////////////// GLOBAL STYLES ////////////////////*/

body {
    font-family: 'Noto Sans JP', sans-serif;
}
.main {
   // background: url(../assets/logincss/images/bg.jpg) no-repeat bottom;
   background: url(../assets/loginbg.png) no-repeat bottom;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -ms-background-size: cover;
    min-height: 100vh;
    padding: 2em 0;
    position: relative;
    z-index: 1;
    justify-content: center;
    display: grid;
    align-items: center;
}
.main:before {
    position: absolute;
    content:'';
    top:0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: -1;
}
.text-center {
    text-align: center;
}

/*/////////////// FONT STYLES ////////////////////*/

.content-w3ls {
    margin: 2em auto;
    padding: 2em 2em;
}
.content-w3ls img{
    margin-bottom: 2em;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border: 4px solid rgb(255 255 255 / 9%);
    display: inline-block;
    width: 64px;
    height: 64px;
    background: #fff;
    border-radius: 50%;
    padding: 14px;
}
.logo h1 a {
    font-size: 42px;
    color: #fff;
    text-transform: capitalize;
    font-weight: 700;
}
/*/////////////// FORM STYLES ////////////////////*/
.wthree-field {
    margin-bottom:14px;
}
.wthree-field input {
    padding: 14px 30px;
    font-size: 16px;
    color: #999;
    letter-spacing: 0.5px;
    border: 1px solid #e1e1e1;
    background: #fff;
    box-sizing: border-box;
    font-family: 'Noto Sans JP', sans-serif;
    width: 100%;
    border-radius: 35px;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    -ms-border-radius: 35px;
    -o-border-radius: 35px;
}

::-webkit-input-placeholder { /* Edge */
    color: #999;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:  #999;
  }
  
  ::placeholder {
    color: #999;
  }

.wthree-field button.btn {
    background: #EA4C89;
    border: none;
    color: #fff;
    padding: 14px 14px;
    text-transform: uppercase;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 16px;
	width:100%;
    letter-spacing:1px;
    outline: none;
    cursor: pointer;
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -o-transition: 0.5s all;
    -ms-transition: 0.5s all;
    border-radius: 35px;
	-webkit-border-radius: 35px;
	-moz-border-radius: 35px;
	-ms-border-radius: 35px;
    -o-border-radius: 35px;

}
.wthree-field button.btn:hover {
    background:#de3d7b;
}
.login-bottom {
    margin-top:3em;
}
.login-bottom a {
    font-size: 16px;
    color: #fff;
    font-weight: normal;
    letter-spacing: 1px;
    padding: 0 5px;
    text-transform: capitalize;
}

.copyright {
    padding: 0 20px;
}
.copyright p {

    color: #fff;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 1px;

}
.copyright p a {
    color: #fff;
}
.copyright p a:hover,.login-bottom a:hover {
    color:#EA4C89;
}
/* -- Responsive code -- */
@media screen and (max-width: 1280px) {
}
@media screen and (max-width: 1080px) {
}
@media screen and (max-width: 900px) {
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 768px) {
	.logo h1 a {
		font-size: 30px;
	}
}
@media screen and (max-width: 668px) {

}
@media screen and (max-width: 600px) {
}
@media screen and (max-width: 568px) {
}

@media screen and (max-width: 480px) {
    
}

@media screen and (max-width: 415px) {
	.logo {
		margin-bottom: 10px;
    }
    .content-w3ls {
        padding: 2em 2em;
    }
}

@media screen and (max-width: 384px) {
    .content-w3ls {
        padding: 1em 1em;
        margin: 1em auto;
    }
    .copyright p {
        letter-spacing: .5px;
    }
}

@media screen and (max-width: 375px) {
    
}
@media screen and (max-width: 320px) {
    
}

/* -- //Responsive code -- */
</style>