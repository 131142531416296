<template>
    <div>
      <Map></Map>
    </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Map from '@/components/MapWf.vue'
import BarEcharts from '@/components/barEcharts.vue'

import { HubConnectionBuilder } from '@microsoft/signalr';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';

export default {
  components: {
    Header,
    Map,
    BarEcharts
  },
  created(){
    this.tableData = this.$store.state.mapMessage
  },
  data() {
      return {
        tableData: [{
          
        }],
        search: ''
      }
    },
    methods: {
      
    },
}
</script>

<style lang="scss" scoped>

</style>
