<template>
    <div class="myMap">
      <div>
        <el-button type="success" style="margin-bottom: 10px; float:right;" size="mini"   plain   @click="changeTableData(null,null)">添加住宅</el-button>
        <el-table
        :data="tableData.filter(data => !search || data.residencename.toLowerCase().includes(search.toLowerCase()))"
        style="width: 100%">
        <el-table-column
          label="街道"
          prop="sweetname">
        </el-table-column>
        <el-table-column
          label="社区"
          prop="communityname">
        </el-table-column>
        <el-table-column
          label="住宅"
          prop="residencename">
        </el-table-column>
        <el-table-column
          align="right">
          <template slot="header" slot-scope="scope">
            <el-input
              v-model="search"
              size="mini"
              placeholder="输入住宅关键字搜索"/>
          </template>
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="watchMap(scope.$index, scope.row)">查看</el-button>
              <el-button
              size="mini"
              @click="changeTableData(scope.$index, scope.row)">编辑</el-button>
              <el-button
              size="mini"
              @click="deleteTableData(scope.$index, scope.row)" type="danger">删除</el-button>
          </template>
        </el-table-column>
          </el-table>

          <el-dialog title="编辑内容" :visible.sync="dialogFormVisible">
            <div class="changeTap">
              <div class="changeTap2">
                <span>ID:</span>
                <el-input
                  placeholder="请输入内容"
                  v-model="changeId"
                  :disabled="true">
                </el-input>
              </div>
              <div class="changeTap2">
                <span>街道:</span>
                <el-select style="width: 100%;" :disabled="adminLv=='1'?false:true" v-model="streetvalue" filterable placeholder="请选择街道">
                  <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="changeTap2">
                <span>社区:</span>
                <el-input
                  placeholder="请输入内容"
                  v-model="changeCommunityName"
                  :disabled="adminLv=='1'||adminLv=='2'?false:true">
                </el-input>
              </div>
              <div class="changeTap2">
                <span>住宅:</span>
                <el-input
                  placeholder="请输入内容"
                  v-model="changeResidenceName">
                </el-input>
              </div>
              <div class="changeTap2">
                <span>坐标:</span>
                <el-input
                  placeholder="经度117°52～118°26，纬度24°23～24°54"
                  v-model="changePosition">
                </el-input>
              </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="changeTableSet">{{ changeButtonName }}</el-button>
              </div>
          </el-dialog>
      </div>
      <div>
        <div  id="container"></div>
        <el-dialog title="提示" ref="input" :visible.sync="dialogVisible" width="20%" :modal="false"
              :before-close="handleClose">
              <p  style="text-align: center;font-size: 25px;">{{ yanzhengHomeName }}</p>
              <p style="text-align: center;font-size: 25px;">请输入验证码</p>
              <el-input ref="input2" v-model="input" @keyup.enter="submitInput" placeholder="请输入验证码"></el-input>
              <span slot="footer" class="dialog-footer">
                  <el-button @click="dialogVisible = false">取 消</el-button>
                  <el-button type="primary" @click="submitInput">确 定</el-button>
              </span>
          </el-dialog>
      </div>
    </div>

</template>


<script>
const loadMapScript = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://webapi.amap.com/maps?v=2.0&key=e8979b3eb74aa74c5b939d2a28384b7c';
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
};
import icon from '../assets/img/icon2.png'
export default {
    data(){
        return{
          changeId:"",
          changeCommunityName:"",
          changeResidenceName:"",
          changePosition:"",
          options: [{
            value: '湖里街道',
            label: '湖里街道'
            }, {
            value: '殿前街道',
            label: '殿前街道'
            }, {
            value: '禾山街道',
            label: '禾山街道'
            }, {
            value: '江头街道',
            label: '江头街道'
            }, {
            value: '金山街道',
            label: '金山街道'
            }, {
            value: '厦门国家火炬高技术产业开发区',
            label: '厦门国家火炬高技术产业开发区'
            }, {
            value: '象屿保税区',
            label: '象屿保税区'
          }],
          streetvalue: '',
          yanzhengHomeName:'',
          input: '',
          homeInfo: '',
          dialogVisible: false,
          dialogFormVisible: false,
          markerData : [
            /*
              { position: [118.182452, 24.5220275], iconUrl: icon ,name: '点1',id:8},
              { position: [118.162452, 24.5300275], iconUrl: icon ,name: '点2',id:2},*/
          ],
          tableData: [{
          
          }],
          search: '',
          map:"",
          adminLv:"",
          userName:"",
          password:"",
          changeButtonName:"修改"
        }
    },
    mounted() {
    loadMapScript().then(() => {
      this.initMap();
    }).catch(error => {
      console.error('加载高德地图脚本失败：', error);
    });
  },
  created(){
    
    this.markerData = this.$store.state.mapMessage
    this.tableData = this.$store.state.mapMessage
    this.adminLv = sessionStorage.getItem("lv")
    this.userName = sessionStorage.getItem("username")
    this.password = sessionStorage.getItem("password")
    console.log(this.markerData)
  },
  methods: {
    initMap() {
      const map = new AMap.Map('container', {
        center: [118.172452, 24.510275],
        zoom: 13,
      });
      
      this.markers = []; // 存储标记点的数组

      // 遍历 markerData 创建标记点并添加到地图和 markers 数组
      if(this.markerData==[] && localStorage.getItem("MapData")!==null){
        this.markerData = JSON.parse(sessionStorage.getItem("MapData"))
      }
      this.markerData.forEach(data => {
        const marker = new AMap.Marker({
          position: data.residenceposition,
          map: map,
          icon: new AMap.Icon({
            image: icon,
            size: new AMap.Size(32, 32),
            anchor: new AMap.Pixel(16, 32),
          }),
          label: {
            content: data.residencename,
            position: "BM",
            minZoom: 15,
          },
        });
        marker.on('click', () => {
          this.input=""
          this.dialogVisible = true
          this.homeInfo={id:data.residenceid,label:{content:  data.residencename},lat:data.residenceposition[1],lng:data.residenceposition[0]}
        });
        this.markers.push(marker);
      });
      this.map=map
    },
    submitInput() {
        if(this.homeInfo.id===9){
            this.$message({
                    message: '本住宅暂未开放',
                    type: 'warning'
                });
            this.dialogVisible = false
            this.input = ''
            return
        }
        localStorage.setItem('homeInfo', JSON.stringify(this.homeInfo))
        localStorage.setItem('MapData',JSON.stringify(this.$store.state.mapMessage))
        this.$http.get(`Home/GetHousePwd?houseId=${this.homeInfo.id}&housePwd=${this.input}`).then(res => {
            console.log('res', res);
            if (res.data) {
              /*
                this.$router.push({
                    path: '/detail',
                })*/
                const url = this.$router.resolve({ path: '/detail' }).href;
                window.open(url, '_blank'); // '_blank'表示在新窗口或新标签页打开
            } else {
                this.$message({
                    message: '验证码输入错误，请重新输入',
                    type: 'warning'
                });

            }
            this.dialogVisible = false
            this.input = ''
            console.log(res);
            console.log('res');
        }).catch((error) => {
            if(error.response.data.status == 500) {
                this.$message({
                    message: '验证码不能为空',
                    type: 'info'
                });
                console.log('error',error);
            }
        })
        console.log(this.input);
        console.log('this.input');

    },
    // 关闭弹出框
    handleClose(done) {
      this.dialogVisible = false
      this.input = ''
    },
    watchMap(index, row) {
      console.log(row)
      this.map.setCenter(row.residenceposition);
      this.map.setZoom(18);
    },
    changeTableData(index, row){
      if(index==null && row ==null){
        this.changeButtonName ="添加"
        this.dialogFormVisible=true
        this.changeId = ""
        this.changeCommunityName = ""
        this.changeResidenceName = ""
        this.changePosition = ""
        this.streetvalue = ""
        if(this.adminLv == "2"){
           this.streetvalue = this.$store.state.mapMessage[0].sweetname
        }else if(this.adminLv == "3"){
          this.streetvalue = this.$store.state.mapMessage[0].sweetname
          this.changeCommunityName = this.$store.state.mapMessage[0].communityname
        }
        
      }else{
        this.changeButtonName ="修改"
        this.dialogFormVisible=true
        this.changeId = row.residenceid
        this.changeCommunityName = row.communityname
        this.changeResidenceName = row.residencename
        this.changePosition = row.residenceposition[0]+","+row.residenceposition[1]
        this.streetvalue = row.sweetname
      }
      
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
    changeTableSet(){
      let position  = this.changePosition.split(',')
      if(position.length!=2){
        alert("输入坐标格式错误")
        this.changePosition=""
        return
      }
      if(Number(position[0])<117.52 || Number(position[0])>118.26 ){
        alert("输入经度超出范围")
        this.changePosition=""
        return
      }

      if(Number(position[1])<24.23 || Number(position[1])>24.54 ){
        alert("输入纬度超出范围")
        this.changePosition=""
        return
      }

      if(this.changeButtonName=="修改"){
        this.$http.post(`Home/WfChangeMapTable?changeId=${this.changeId}&changeCommunityName=${this.changeCommunityName}&changeResidenceName=${this.changeResidenceName}&changePosition=${this.changePosition}&streetvalue=${this.streetvalue}&lv=${this.adminLv}&username=${this.userName}&password=${this.password}`).then(res => {
        if(res.status === 200) {
            console.log("修改后的数据",res.data)
            if(res.data.mapMessageData!=null){
                res.data.mapMessageData.forEach((item)=>{
                    let strings = item.residenceposition.split(',')
                    let double1 = Number(strings[0])
                    let double2 = Number(strings[1])
                    item.residenceposition=[double1,double2]
                })
            }
            this.$store.state.mapMessage=res.data.mapMessageData
            this.dialogFormVisible=false
            this.markerData = this.$store.state.mapMessage
            this.tableData = this.$store.state.mapMessage
            console.log("修改后的数据", this.markerData)
            this.initMap()
        }else{
            alert('连接错误,请稍后再试')
        }
      })
    
      }else if(this.changeButtonName=="添加"){
        this.$http.post(`Home/WfAddMapTable?changeCommunityName=${this.changeCommunityName}&changeResidenceName=${this.changeResidenceName}&changePosition=${this.changePosition}&streetvalue=${this.streetvalue}&lv=${this.adminLv}&username=${this.userName}&password=${this.password}`).then(res => {
        if(res.status === 200) {
            console.log("修改后的数据",res.data)
            if(res.data.mapMessageData!=null){
                res.data.mapMessageData.forEach((item)=>{
                    let strings = item.residenceposition.split(',')
                    let double1 = Number(strings[0])
                    let double2 = Number(strings[1])
                    item.residenceposition=[double1,double2]
                })
            }
            this.$store.state.mapMessage=res.data.mapMessageData
            this.dialogFormVisible=false
            this.markerData = this.$store.state.mapMessage
            this.tableData = this.$store.state.mapMessage
            console.log("添加后的数据", this.markerData)
            this.initMap()
        }else{
            alert('连接错误,请稍后再试')
        }
      })
    
      }
    },
    deleteTableData(index, row){
      this.$http.post(`Home/WfDeleteMapTable?changeId=${row.residenceid}&changeCommunityName=${row.communityname}&changeResidenceName=${row.residencename}&streetvalue=${row.sweetname}&lv=${this.adminLv}&username=${this.userName}&password=${this.password}`).then(res => {
        if(res.status === 200) {
            console.log("修改后的数据",res.data)
            if(res.data.mapMessageData!=null){
                res.data.mapMessageData.forEach((item)=>{
                    let strings = item.residenceposition.split(',')
                    let double1 = Number(strings[0])
                    let double2 = Number(strings[1])
                    item.residenceposition=[double1,double2]
                })
            }
            this.$store.state.mapMessage=res.data.mapMessageData
            this.dialogFormVisible=false
            this.markerData = this.$store.state.mapMessage
            this.tableData = this.$store.state.mapMessage
            console.log("修改后的数据", this.markerData)
            this.initMap()
        }else{
            alert('连接错误,请稍后再试')
        }
      })
    }
  }
}
</script>
<style lang="scss" >
#container {
  width: 100%;
  height: 1080px;
}
.myMap{
  display: flex;

}
.myMap > div:first-child {
  width: 40%;
  margin-right: 10px;
}

.myMap > div:last-child {
  width: 59%;
}
.changeTap {
    display: flex;
    flex-direction: column;
    gap: 10px; /* 设置changeTap内的子元素（也就是changeTap2的div）之间的间隔为10px */
}

.changeTap2 {
    display: flex;
    justify-content: space-between; /* 让span和el-input在水平方向两端分布，便于后续对齐等操作 */
    align-items: center; /* 让span和el-input在垂直方向上居中对齐 */
    gap: 10px;
    width: 40%;
}

.changeTap2 span {
    text-align: right; /* 让span里的文字靠右对齐 */
    flex: 0 0 20%; /* 可以根据实际情况调整占比，这里设置span占30%宽度 */
}

.changeTap2 el-input {
    flex: 0 0 35%; /* 根据整体布局，设置el-input占60%宽度，可按需调整 */
}

</style>
