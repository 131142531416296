<template>
    <div>
      <div class="change2">
          <el-button type="success"  round  @click="addDetail">添加用户</el-button>
          <el-button type="info"  @click="refreshTable" round>刷新表格</el-button>
      </div>
        <el-table
      :data="tableData.filter(data => !search || data.username.toLowerCase().includes(search.toLowerCase()))"
      style="width: 100%">
      <el-table-column
        label="Username"
        prop="username">
      </el-table-column>
      <el-table-column
        label="Password"
        prop="password">
      </el-table-column>
      <el-table-column
        label="Phone"
        prop="phone">
      </el-table-column>
      <el-table-column
        label="Permission"
        prop="permission">
      </el-table-column>
      <el-table-column
        label="Information"
        prop="information">
      </el-table-column>
      <el-table-column
        label="Registertime"
        prop="registertime">
      </el-table-column>
      <el-table-column
        align="right">
        <template slot="header" slot-scope="scope">
          <el-input
            v-model="search"
            size="mini"
            placeholder="输入关键字搜索"/>
        </template>
        <template slot-scope="scope">
          <el-button
            size="mini"
            @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
        </el-table>
        <el-drawer
            title="用户资料"
            :visible.sync="drawer"
            :direction="direction"
            >
            <div class="change">
                <div>
                    Name
                </div>
                <div>
                    <el-input
                    v-model="changeName"
                    :disabled="nameDisabled"
                    >
                </el-input>
                </div>
            </div>
            <div class="change">
                <div>
                    Password
                </div>
                <div>
                    <el-input
                    v-model="changePassword"
                    >
                </el-input>
                </div>
            </div>
            <div class="change">
                <div>
                    Phone
                </div>
                <div>
                    <el-input
                    v-model="changePhone">
                </el-input>
                </div>
            </div>

            <div class="change">
                <div>
                    Permission
                </div>
                <div>
                    <el-input
                    v-model="changePermission">
                </el-input>
                </div>
            </div>

            <div class="change">
                <div>
                    Information
                </div>
                <div>
                    <el-input
                    v-model="changeInformation">
                </el-input>
                </div>
            </div>
            <div class="change">
              <el-button type="success" size="mini" round @click="changeDetail">{{changeButton1Name}}</el-button>
              <el-button type="info" size="mini" @click="closeDrawer" round>{{changeButton2Name}}</el-button>
            </div>
            
        </el-drawer>
    </div>
</template>
  
  <script>
    export default {
      data() {
        return {
          drawer: false,
          direction: 'rtl',
          changeName:"",
          changePhone:"",
          changePermission:"",
          changeInformation:"",
          changePassword:"",
          tableData: [],
          search: '',
          nameDisabled:false,
          changeButton1Name:"确定修改",
          changeButton2Name:"取消修改",
        }
      },
      created(){
        this.$http.get(`Home/WfUserSelect`).then(res => {
            if(res.status === 200) {
                
                console.log(res.data.data)
                this.tableData=res.data.data
            }else{
                alert('连接错误,请稍后再试')
            }
        })
      },
      methods: {
        handleEdit(index, row) {
          this.drawer = true
          this.nameDisabled=true
          this.changeButton1Name="确定修改"
          this.changeButton2Name="取消修改"
          this.changeName = row["username"]
          this.changePhone = row["phone"]
          this.changePermission = row["permission"]
          this.changeInformation = row["information"]
          this.changePassword = row["password"]
        },
        /*
        handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
        },*/
        handleDelete(index, row) {
          this.changeName = row["username"]
          this.changePhone = row["phone"]
          this.changePermission = row["permission"]
          this.changeInformation = row["information"]
          this.changePassword = row["password"]
          this.$http.post(`Home/WfUserDetailDelete?username=${this.changeName}&password=${this.changePassword}&phone=${this.changePhone}&permission=${this.changePermission}&information=${this.changeInformation}`).then(res => {
              if(res.status === 200) {
                  alert("修改成功")
              }else{
                  alert('连接错误,请稍后再试')
              }
            })
        },
        closeDrawer(){
          this.drawer=false
        },
        changeDetail(){
          if(this.changeButton1Name=="确定修改"){
            this.$http.post(`Home/WfUserDetailChange?username=${this.changeName}&password=${this.changePassword}&phone=${this.changePhone}&permission=${this.changePermission}&information=${this.changeInformation}`).then(res => {
              if(res.status === 200) {
                  alert("修改成功")
              }else{
                  alert('连接错误,请稍后再试')
              }
            })
          }else if(this.changeButton1Name=="确定添加"){
            this.$http.post(`Home/WfUserDetailAdd?username=${this.changeName}&password=${this.changePassword}&phone=${this.changePhone}&permission=${this.changePermission}&information=${this.changeInformation}`).then(res => {
              if(res.status === 200) {
                  alert("添加成功 ")
              }else{
                  alert('连接错误,请稍后再试')
              }
            })
          }
          
        },
        addDetail(){
          this.drawer = true
          this.nameDisabled=false
          this.changeButton1Name="确定添加"
          this.changeButton2Name="取消添加"
          this.changeName = ""
          this.changePhone = ""
          this.changePermission = ""
          this.changeInformation = ""
          this.changePassword = ""
        },
        refreshTable(){
          this.tableData=[]
          this.$http.get(`Home/WfUserSelect`).then(res => {
            if(res.status === 200) {
                this.tableData=res.data.data
              }else{
                  alert('连接错误,请稍后再试')
              }
          })
        }

      },
    }
  </script>
<style>
.change{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: left;
    gap: 10px;
    margin-top: 10px;
}
.change2{
  display: flex;
  justify-content: right;
  align-content: right;
  gap: 10px;
  margin-bottom: 10px;
}
</style>