import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    mapMessage:[],
    mapData:[]
  },
  getters: {
  },
  mutations: {
    setLoggedIn(state, value) {
      state.isLoggedIn = value;
    },
    setmapMessage(state, value){
      state.mapMessage = value;
    }
  },
  actions: {
  },
  modules: {
  }
})
