import Vue from 'vue'
import ElementUI from 'element-ui'
import App from './App.vue'
import router from './router'
import store from './store'
// 将自动注册所有组件为全局组件

import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

// 引入滚动条样式
import "simplebar-vue/dist/simplebar.min.css";

// 引入样式
import 'element-ui/lib/theme-chalk/index.css'
// 按需引入组件
import { Dialog, Input, Message, Alert, Table, TableColumn } from 'element-ui'

Vue.use(ElementUI)
Vue.component(Dialog.name, Dialog)
Vue.component(Input.name, Input)
Vue.component(Message.name, Message)
Vue.component(Alert.name, Alert)
Vue.component(Table.name, Table)
Vue.component(TableColumn.name, TableColumn)




// 1: 导入 axios
import axios from 'axios'
 
// 2: 配置请求根路径
axios.defaults.baseURL = 'http://114.132.77.194:8081/'
//axios.defaults.baseURL = 'https://localhost:7248'
// 3: 通过Vue 构造函数的原型对象，全局配置 axios

Vue.prototype.$http = axios
// 引入echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 引入全局css
import './assets/scss/style.scss';

// 引入地图组件

import AMap from 'vue-amap';
Vue.use(AMap);
 /*
  // 初始化vue-amap
AMap.initAMapApiLoader({
  // 高德key
  key: 'e8979b3eb74aa74c5b939d2a28384b7c',
  // 插件集合 （插件按需引入）
  plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PlaceSearch', 'AMap.Geolocation', 'AMap.Geocoder','AMap.Marker'],
  uiVersion: '1.0' , //添加 uiVersion 的脚本版本号
});*/
//高德的安全密钥
window._AMapSecurityConfig = {
	securityJsCode:'592c04337bb61441ac7864cf989f301a',
}


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
