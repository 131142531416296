import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Detail from '../views/Detail.vue'
import ueModel from '../views/ueModel.vue'
import HomeViewWf from '@/views/HomeViewWf.vue'
import Login from '@/views/Login.vue'
import store from '@/store'
import wfHome from '@/views/wfHome.vue'
import wfSuperPage from '@/components/wfSuperPage.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/HomeView',
    name: 'HomeView',
    component: HomeView,
    // children: [{
    //   path: '/detail',
    //   name: 'detail',
    //   component: () => {
    //     return import("../views/Detail.vue")
    //   }
    // }]
  },
  {
    path: '/',
    name: 'home',
    component: wfHome,
    beforeEnter: (to, from, next) => {
      if (!store.state.isLoggedIn|| !sessionStorage.getItem("isLoggedIn")) {
        // 如果用户未登录，重定向到登录页面
        next('/login');
      } else {
        next();
      }
    }
  },
  {
    path:'/ueModel/:id/:name/:sensor/:table',
    name:'ueModel',
    component: ueModel,
  },
  {
    path:'/detail',
    name:'detail',
    component: Detail,
  },
  {
    path: '/VRModel',
    name: 'VRModel',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/vrModel.vue')
  },

  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/wfHome',
    name: 'wfHome',
    component: wfHome,
  },

]

const router = new VueRouter({
  routes
})

export default router
